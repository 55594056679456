export function parseEventAchievementObject(edge) {
  var _edge$node$icon;
  var item = {
    id: edge.node.id,
    type: edge.node.type || undefined,
    eventValue: edge.node.event_value || 0,
    eventName: edge.node.event_name,
    eventDescription: edge.node.event_description || '',
    subject: edge.node.event_subject || undefined,
    imageUrl: (_edge$node$icon = edge.node.icon) === null || _edge$node$icon === void 0 ? void 0 : _edge$node$icon.url
  };
  return item;
}