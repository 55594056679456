import { useQuery } from '@apollo/client';
import usePlayroomOrganizationStatus from "../usePlayroomOrganizationStatus";
var GET_PLAYROOM_ORGANIZATION_CONFIGURATION_QUERY = { kind: "Document", definitions: [{ kind: "OperationDefinition", operation: "query", name: { kind: "Name", value: "GetPlayroomOrganizationConfigurationQuery" }, variableDefinitions: [{ kind: "VariableDefinition", variable: { kind: "Variable", name: { kind: "Name", value: "slug" } }, type: { kind: "NamedType", name: { kind: "Name", value: "String" } }, directives: [] }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "organization" }, arguments: [{ kind: "Argument", name: { kind: "Name", value: "slug" }, value: { kind: "Variable", name: { kind: "Name", value: "slug" } } }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "configuration" }, arguments: [], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "hasGamificationAvailable" }, arguments: [], directives: [] }] } }] } }] } }], loc: { start: 0, end: 158, source: { body: "query GetPlayroomOrganizationConfigurationQuery($slug: String) {\n  organization(slug: $slug) {\n    configuration {\n      hasGamificationAvailable\n    }\n  }\n}\n", name: "GraphQL request", locationOffset: { line: 1, column: 1 } } } };
var useIsPlayRoomActivated = function useIsPlayRoomActivated(organizationSlug) {
  var _data$organization, _data$organization$co;
  var shouldSkipCallQuery = !organizationSlug;
  var _useQuery = useQuery(GET_PLAYROOM_ORGANIZATION_CONFIGURATION_QUERY, {
      variables: {
        slug: organizationSlug
      },
      context: {
        clientName: 'b2b'
      },
      skip: shouldSkipCallQuery
    }),
    data = _useQuery.data,
    loading = _useQuery.loading,
    error = _useQuery.error;
  var hasPlayroomAvailable = !!(data !== null && data !== void 0 && (_data$organization = data.organization) !== null && _data$organization !== void 0 && (_data$organization$co = _data$organization.configuration) !== null && _data$organization$co !== void 0 && _data$organization$co.hasGamificationAvailable);
  var _usePlayroomOrganizat = usePlayroomOrganizationStatus(organizationSlug, {
      skip: !hasPlayroomAvailable
    }),
    isLoadingPlayroomOrgStatus = _usePlayroomOrganizat.loading,
    isPlayroomPublished = _usePlayroomOrganizat.isPlayroomPublished;
  var isLoading = isLoadingPlayroomOrgStatus || loading;
  var isActivated = hasPlayroomAvailable && isPlayroomPublished;
  return {
    isActivated: isActivated,
    loading: isLoading,
    error: error
  };
};
export default useIsPlayRoomActivated;