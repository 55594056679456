import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
import { customLocalStorage } from '@crehana/utils';

/**
 * Like React.useState but with localStorage support baked in, so your state persist between page navigation and reloads, be sure tu use very unique name for key.
 * @param defaultValue
 * @param key
 */
function useStickyState(defaultValue, key) {
  var _useState = useState(function () {
      if (key.length > 0) {
        var stickyValue = typeof window === 'undefined' ? null : customLocalStorage.getItem(key);
        return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
      }
      return [];
    }),
    _useState2 = _slicedToArray(_useState, 2),
    value = _useState2[0],
    setValue = _useState2[1];
  useEffect(function () {
    var stickyValue = customLocalStorage.getItem(key);
    if (stickyValue !== null) {
      setValue(JSON.parse(stickyValue));
    }
  }, [key]);
  useEffect(function () {
    if (key.length > 0) {
      customLocalStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);
  return [value, setValue];
}
export default useStickyState;