import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["data", "loading", "error"];
import { useQuery } from '@apollo/client';
import { CurrencyPointStatusEnum } from "../../types/graphql/globals.gamification";
var GET_PLAYROOM_ORGANIZATION_CONFIGURATION_QUERY = { kind: "Document", definitions: [{ kind: "OperationDefinition", operation: "query", name: { kind: "Name", value: "GetPlayroomOrganizationStatusQuery" }, variableDefinitions: [{ kind: "VariableDefinition", variable: { kind: "Variable", name: { kind: "Name", value: "slug" } }, type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } }, directives: [] }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "currency_point_detail" }, arguments: [{ kind: "Argument", name: { kind: "Name", value: "slug" }, value: { kind: "Variable", name: { kind: "Name", value: "slug" } } }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "status_enum" }, arguments: [], directives: [] }] } }] } }], loc: { start: 0, end: 120, source: { body: "query GetPlayroomOrganizationStatusQuery($slug: String!) {\n  currency_point_detail(slug: $slug) {\n    status_enum\n  }\n}\n", name: "GraphQL request", locationOffset: { line: 1, column: 1 } } } };
var usePlayroomOrganizationStatus = function usePlayroomOrganizationStatus(organizationSlug, args) {
  var _data$currency_point_;
  var shouldSkipCallQuery = !!(args !== null && args !== void 0 && args.skip);
  var _useQuery = useQuery(GET_PLAYROOM_ORGANIZATION_CONFIGURATION_QUERY, {
      variables: {
        slug: organizationSlug || ''
      },
      context: {
        clientName: 'v5'
      },
      skip: shouldSkipCallQuery
    }),
    data = _useQuery.data,
    loading = _useQuery.loading,
    error = _useQuery.error,
    rest = _objectWithoutProperties(_useQuery, _excluded);
  var statusEnum = data === null || data === void 0 ? void 0 : (_data$currency_point_ = data.currency_point_detail) === null || _data$currency_point_ === void 0 ? void 0 : _data$currency_point_.status_enum;
  var isPlayroomPublished = statusEnum === CurrencyPointStatusEnum.PUBLISHED;
  var isDraft = statusEnum === CurrencyPointStatusEnum.DRAFT;
  return _objectSpread({
    data: data,
    isPlayroomPublished: isPlayroomPublished,
    isDraft: isDraft,
    loading: loading,
    error: error
  }, rest);
};
export default usePlayroomOrganizationStatus;