import { useMemo } from 'react';
import { notEmpty } from '@crehana/utils';
import useAchievementList from "../useAchievementList";
var useAchievementByType = function useAchievementByType(args) {
  var _useAchievementList = useAchievementList(args.organizationId, {
      skip: args === null || args === void 0 ? void 0 : args.skip
    }),
    loading = _useAchievementList.loading,
    data = _useAchievementList.data,
    error = _useAchievementList.error;
  var achievements = useMemo(function () {
    if (data.length > 0 && args.by) {
      var datasetAchievements = new Map();
      data.forEach(function (achievement) {
        datasetAchievements.set(achievement.subject, achievement);
      });
      return args.by.map(function (subject) {
        return datasetAchievements.get(subject);
      }).filter(notEmpty);
    }
    return [];
  }, [data, args.by]);
  var achievementCoins = achievements.map(function (a) {
    var _a$eventValue;
    return (_a$eventValue = a === null || a === void 0 ? void 0 : a.eventValue) !== null && _a$eventValue !== void 0 ? _a$eventValue : 0;
  });
  return {
    loading: loading,
    achievements: achievements,
    achievementCoins: achievementCoins,
    error: error
  };
};
export default useAchievementByType;